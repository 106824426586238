"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var reducers_1 = require("../state/workspace/reducers");

exports.darkColours = {
  green: '#27ae60',
  darkBlue: 'rgb(23, 42, 58)',
  darkBlue50: 'rgba(23, 42, 58, 0.5)',
  darkBlue80: 'rgba(23, 42, 58, 0.8)',
  darkBlue60: 'rgba(23, 42, 58, 0.6)',
  darkBlue30: 'rgba(23, 42, 58, 0.3)',
  darkBlue20: 'rgba(23, 42, 58, 0.2)',
  darkBlue10: 'rgba(23, 42, 58, 0.1)',
  darkerBlue: '#0F202D',
  darkestBlue: 'rgb(11,20,28)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white: 'rgba(255, 255, 255, 1)',
  black02: 'rgba(0, 0, 0, 0.02)',
  black07: 'rgba(0, 0, 0, 0.07)',
  black04: 'rgba(0, 0, 0, 0.04)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  red: '#f25c54',
  orange: 'rgba(241, 143, 1, 1)',
  blue: 'rgba(42, 126, 210, 1)',
  purple: 'rgb(164, 3, 111)',
  paleText: 'rgba(0, 0, 0, 0.5)',
  paleGrey: '#f3f4f4',
  lightGrey: '#eeeff0',
  lighterGrey: '#f6f7f7',
  // New colors
  text: 'rgba(255,255,255,0.6)',
  textInactive: '#555e66'
};
exports.lightColours = {
  green: '#27ae60',
  darkBlue: 'rgb(23, 42, 58)',
  darkBlue50: 'rgba(23, 42, 58, 0.5)',
  darkBlue80: 'rgba(23, 42, 58, 0.8)',
  darkBlue60: 'rgba(23, 42, 58, 0.6)',
  darkBlue30: 'rgba(23, 42, 58, 0.3)',
  darkBlue20: 'rgba(23, 42, 58, 0.2)',
  darkBlue10: 'rgba(23, 42, 58, 0.1)',
  darkerBlue: '#0F202D',
  darkestBlue: 'rgb(11,20,28)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white: 'rgba(255, 255, 255, 1)',
  black02: 'rgba(0, 0, 0, 0.02)',
  black04: 'rgba(0, 0, 0, 0.04)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black07: 'rgba(0, 0, 0, 0.07)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  red: '#f25c54',
  orange: 'rgba(241, 143, 1, 1)',
  blue: 'rgba(42, 126, 210, 1)',
  purple: 'rgb(164, 3, 111)',
  paleText: 'rgba(0, 0, 0, 0.5)',
  paleGrey: '#f3f4f4',
  lightGrey: '#eeeff0',
  lighterGrey: '#f6f7f7',
  // New colors
  text: 'rgba(0,0,0,.7)',
  textInactive: 'rgba(0,0,0,.3)'
};
exports.darkEditorColours = {
  property: 'rgb(41, 185, 115)',
  comment: 'rgba(255, 255, 255, 0.3)',
  punctuation: 'rgba(255, 255, 255, 0.4)',
  keyword: 'rgb(42, 126, 211)',
  def: 'rgb(56, 189, 193)',
  qualifier: '#1c92a9',
  attribute: 'rgb(247, 116, 102)',
  number: '#2882f9',
  string: '#d64292',
  builtin: '#d47509',
  string2: '#0b7fc7',
  variable: 'rgb(181, 34, 130)',
  meta: '#b33086',
  atom: 'rgb(249, 233, 34)',
  ws: 'rgba(255, 255, 255, 0.4)',
  selection: 'rgba(255, 255, 255, 0.1)',
  cursorColor: 'rgba(255, 255, 255, 0.4)',
  text: '#fff',
  textInactive: 'rgba(255, 255, 255, 0.6)',
  background: '#09141c',
  sidebarTop: '#0f202d',
  sidebar: '#172b3a',
  sidebarBottom: '#172b3a',
  sidebarItemActive: 'rgb(23, 42, 58)',
  sidebarItemSide: '#27ae60',
  sidebarItemSessions: 'rgba(255, 255, 255, 0.05)',
  tab: '#172b3a',
  tabInactive: '#0f202d',
  tabText: '#fff',
  navigationBar: '#172b3a',
  navigationBarText: 'rgba(255, 255, 255, 0.6)',
  editorBackground: '#0f202d',
  resultBackground: '#172b3a',
  leftDrawerBackground: '#0b1924',
  rightDrawerBackground: '#0b1924',
  drawerText: 'rgba(255,255,255,0.6)',
  drawerTextInactive: '#555e66',
  executeButton: 'rgb(185, 191, 196)',
  executeButtonBorder: 'rgb(11, 20, 28)',
  executeButtonHover: 'rgb(195, 201, 206)',
  executeButtonSubscription: '#f25c54',
  executeButtonSubscriptionHover: '#f36c65',
  icon: 'rgb(74, 85, 95)',
  iconHover: 'rgba(255, 255, 255, 0.6)',
  pollingIcon: 'rgba(139, 149, 156, 1)',
  pollingIconShadow: 'rgba(139, 149, 156, 0.4)',
  button: '#0F202D',
  buttonHover: '#122535',
  buttonText: 'rgba(255,255,255,0.6)',
  buttonWorkspace: '#b9bfc4',
  buttonWorkspaceHover: '#a4acb2',
  buttonWorkspaceText: 'rgb(23, 42, 58)',
  circle: 'rgba(255, 255, 255, 0.4)',
  subscriptionTimeBoaderTop: 'rgba(255, 255, 255, 0.2)',
  subscriptionTimeText: 'rgba(255, 255, 255, 0.5)'
};
exports.lightEditorColours = {
  property: '#328c8c',
  comment: 'rgba(0, 0, 0, 0.3)',
  punctuation: 'rgba(23,42,58,.8)',
  keyword: '#366b6b',
  def: 'rgb(56, 189, 193)',
  qualifier: '#1c92a9',
  attribute: '#b56531',
  number: '#1f6ed6;',
  string: '#d64292',
  builtin: '#d47509',
  string2: '#0b7fc7',
  variable: 'rgb(236, 95, 103)',
  meta: '#b33086',
  atom: 'rgb(245, 160, 0)',
  ws: 'rgba(23, 42, 58, 0.8)',
  selection: '#d1e9fd',
  cursorColor: 'rgba(0, 0, 0, 0.4)',
  text: 'rgba(0, 0, 0, 0.7)',
  textInactive: 'rgba(0, 0, 0, 0.3)',
  background: '#dbdee0',
  sidebarTop: '#eeeff0',
  sidebar: '#eeeff0',
  sidebarBottom: '#f6f7f7',
  sidebarItemActive: '#f6f7f7',
  sidebarItemSide: '#27ae60',
  sidebarItemSessions: '#dbdee0',
  tab: '#eeeff0',
  tabInactive: '#e7eaec',
  tabText: 'rgba(23, 42, 58, .8)',
  navigationBar: '#eeeff0',
  navigationBarText: 'rgba(23, 42, 58, 0.8)',
  editorBackground: '#f6f7f7',
  resultBackground: '#eeeff0',
  leftDrawerBackground: '#e9eaea',
  rightDrawerBackground: '#e5e7e7',
  drawerText: 'rgba(0, 0, 0, 0.7)',
  drawerTextInactive: 'rgba(0, 0, 0, 0.3)',
  executeButton: 'rgb(115, 127, 136)',
  executeButtonBorder: '#eeeff0',
  executeButtonHover: '',
  executeButtonSubscription: '#f25c54',
  executeButtonSubscriptionHover: '#f36c65',
  icon: 'rgb(194, 200, 203)',
  iconHover: 'rgba(23, 42, 58, 0.6)',
  pollingIcon: 'rgba(139, 149, 156, 1)',
  pollingIconShadow: 'rgba(139, 149, 156, 0.4)',
  button: '#d8dbde',
  buttonHover: 'rgba(20, 37, 51, 0.2)',
  buttonText: 'rgba(23, 42, 58, 0.8)',
  buttonWorkspace: 'rgb(185, 191, 196)',
  buttonWorkspaceHover: 'rgb(157, 166, 173)',
  buttonWorkspaceText: 'rgb(238, 239, 240)',
  circle: 'rgba(23,42,58,.4)',
  subscriptionTimeBoaderTop: 'rgba(23, 42, 58, 0.2)',
  subscriptionTimeText: 'rgba(23, 42, 58, 0.5)'
};
exports.sizes = {
  small6: '6px',
  small10: '10px',
  small12: '12px',
  small16: '16px',
  medium25: '25px',
  // font weights
  fontLight: '300',
  fontSemiBold: '600',
  // font sizes
  fontTiny: '12px',
  fontSmall: '14px',
  fontMedium: '20px',
  // others
  smallRadius: '2px'
};
exports.shorthands = {};
exports.theme = {
  mode: 'dark',
  colours: exports.darkColours,
  sizes: exports.sizes,
  shorthands: exports.shorthands,
  editorColours: exports.darkEditorColours,
  settings: reducers_1.defaultSettings
};