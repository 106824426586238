import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {store, setSettingsString} from 'graphql-playground-react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import GqlPlayground from './gql-playground'
import keycloak from "./keycloak"


const eventLogger = (event: unknown, error: unknown) => {
    console.debug('Keycloak onEvent', event, error)
}

const tokenLogger = (tokens: unknown) => {
    console.debug('Keycloak onTokens ', tokens);
    console.log("Updating auth header");
    let settings = JSON.parse(store.getState().settingsString);
    settings["request.globalHeaders"] = {
        authorization: `Bearer ${keycloak.idToken}`,
    };
    store.dispatch(setSettingsString(JSON.stringify(settings, null, 2)));
}

ReactDOM.render(
    <Provider store={store}>
        <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={eventLogger}
            onTokens={tokenLogger}
            initOptions={{onLoad: "login-required", checkLoginIframe : false}}
        >
            <GqlPlayground />
        </ReactKeycloakProvider>
    </Provider>,
    document.getElementById("app")
)