import React from 'react'
import { connect } from 'react-redux'
import { Playground } from 'graphql-playground-react'
import { useKeycloak } from '@react-keycloak/web'

const GqlPlayground = () => {
    const { keycloak } = useKeycloak()
    return (
        <>
            {!keycloak.authenticated && (
                <div className="spinner-wrapper">
                    <div className="spinner-node" />
                </div>
            )}
            {!!keycloak.authenticated && (
                <Playground endpoint={process.env.GRAPHQL_ENDPOINT_URL || (window.location.protocol + "//" + window.location.host + "/graphql")} />
            )}
        </>
    )
}

export default connect(
    null,
    null,
)(GqlPlayground)